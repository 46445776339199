import React, { useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
/* components */
import Glide from "@glidejs/glide";
import heroImage from "../../../../../assets/images.png";
import doctorImage from "../../../../../assets/doctor.png";
/* style */
import * as S from "./styles";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";

const Carousel = () => {
  const carouselRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      const glide = new Glide(carouselRef.current, {
        type: "carousel",
        perView: 1,
        autoplay: 6000,
        hoverpause: true,
      });

      glide.mount();

      return () => {
        glide.destroy();
      };
    }
  }, []);

  return (
    <S.CarouselContainer ref={carouselRef}>
      <div class="glide__bullets" data-glide-el="controls">
        <button class="glide__bullet" data-glide-dir="=0"></button>
        <button class="glide__bullet" data-glide-dir="=1"></button>
      </div>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          <li className="glide__slide">
            <S.HeroContainerEvent>
              <S.TextContainer>
                <S.Title>IV Encontro de Urologia 2023</S.Title>
                <S.SubtitleEvent>
                  O IV Encontro de Urologia é um simpósio dedicado à discussão
                  de alguns dos temas mais pertinentes em Urologia na
                  atualidade.
                </S.SubtitleEvent>
                <S.Subtitle>
                  Casino Park hotel | 27 de outubro de 2023 | A partir das 14h.
                </S.Subtitle>
                <S.ButtonContainer>
                  <S.WebButtonEvent to={"/eventos"} title="Ir para Formulário">
                    Saiba mais e Inscreva-se!
                  </S.WebButtonEvent>
                </S.ButtonContainer>
              </S.TextContainer>
              <S.ImageContainer>
                <S.ImageHero src={doctorImage} />
                <S.CopyRightsLink
                  target="_blank"
                  href="https://br.freepik.com/fotos-gratis/doutor-em-pe-com-uma-pasta-e-um-estetoscopio_998783.htm"
                >
                  Imagem de Iaros no Freepik
                </S.CopyRightsLink>
              </S.ImageContainer>
            </S.HeroContainerEvent>
          </li>
          <li className="glide__slide">
            <S.HeroContainer>
              <S.TextContainer>
                <S.Title>TOTAL CONTROLO DAS DESPESAS DE REPRESENTAÇÃO</S.Title>
                <S.Subtitle>
                  A única plataforma digital desenvolvida de raiz
                  especificamente para assegurar de forma eficaz toda a
                  informação, gestão e controlo das despesas de representação.
                </S.Subtitle>
                <S.ButtonContainer>
                  <S.WebButton
                    href="https://create-booking-dashboard.azurewebsites.net/app/login"
                    target="_blank"
                    title="Ir para Create Booking"
                  >
                    Acessar Web
                  </S.WebButton>
                  <S.AppButton
                    href="https://apps.apple.com/br/app/create-booking-manager/id1519261832"
                    target="_blank"
                    title="Ir para App Store"
                  >
                    App iOS
                  </S.AppButton>
                  <S.AppButton
                    href="https://play.google.com/store/apps/details?id=com.createbooking"
                    target="_blank"
                    title="Ir para Play Store"
                  >
                    App Android
                  </S.AppButton>
                </S.ButtonContainer>
              </S.TextContainer>
              <S.ImageContainer>
                <S.ImageHero src={heroImage} />
              </S.ImageContainer>
            </S.HeroContainer>
          </li>
        </ul>
      </div>
    </S.CarouselContainer>
  );
};

export default Carousel;
