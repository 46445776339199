import heroImage from "../../../../assets/images.png";
import { CheckSquareOffset, Clock, PresentationChart } from "phosphor-react";
import { useTheme } from "styled-components";

import * as S from "./styles";
import Carousel from "./Carousel";

export const Hero = () => {
  const theme = useTheme();
  return (
    <div>
      <S.Container id="home">
        <Carousel />
      </S.Container>
      <S.CardList>
        <S.CardContainer>
          <S.CardHeader>
            <CheckSquareOffset
              size={32}
              weight="fill"
              color={theme.brand.green}
            />
            <S.CardTitle>Centraliza e paga as reservas</S.CardTitle>
          </S.CardHeader>
          <S.CardBody>
            <S.CardText>
              Total controlo sobre as despesas de representação e uma única
              fatura mensal para todas as despesas
            </S.CardText>
          </S.CardBody>
        </S.CardContainer>
        <S.CardContainer>
          <S.CardHeader>
            <Clock size={32} weight="regular" color={theme.brand.green} />
            <S.CardTitle>Toda a informação em tempo real</S.CardTitle>
          </S.CardHeader>
          <S.CardBody>
            <S.CardText>
              Relatórios online parametrizados e notificações em todas as fazes
              do processo
            </S.CardText>
          </S.CardBody>
        </S.CardContainer>
        <S.CardContainer>
          <S.CardHeader>
            <PresentationChart
              size={32}
              weight="regular"
              color={theme.brand.green}
            />
            <S.CardTitle>Evita despesas não controladas</S.CardTitle>
          </S.CardHeader>
          <S.CardBody>
            <S.CardText>
              Além de uma rede de restaurantes com desconto você tem acesso
              antecipado à informação e à previsão de custos
            </S.CardText>
          </S.CardBody>
        </S.CardContainer>
      </S.CardList>
    </div>
  );
};
