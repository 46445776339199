/* styles */
import { useState } from "react";
import { ChevronDown } from "lucide-react";
import sesaramLogo from "../../../../assets/sesaram-logo.png";
import urologiaDoc from "../../../../assets/docs/iv-encontro-da-urologia.pdf";
import { PrimaryBtn } from "../../../../components/Button";
/* styles */
import * as S from "./styles";

const EventCard = () => {
  const [showContent, setShowContent] = useState(true);

  const handleShowContent = () => {
    setShowContent(!showContent);
  };

  const handleDownload = (file) => {
    const anchor = document.createElement("a");
    anchor.href = file;
    anchor.download = "IV Encontro da Urologia.pdf";
    anchor.click();
  };

  return (
    <S.Container>
      <S.Card>
        <S.EventTitle> IV Encontro de Urologia 2023</S.EventTitle>
        <PrimaryBtn
          bgColor={"#1f3864"}
          onClick={() => handleDownload(urologiaDoc)}
          title="Download IV Encontro de Urologia 2023.pdf"
        >
          Programa
        </PrimaryBtn>
        <PrimaryBtn bgColor={"#ffd966"} txtColor={"#1f3864"}>
          <a
            title="Ir para formulário de inscrição IV Encontro de Urologia 2023"
            style={{ all: "unset", fontWeight: 600 }}
            href="https://docs.google.com/forms/d/e/1FAIpQLSfhwBqQoQGi-h1ONRIxnoT77JY6mtNj3J_sKodb_C0bB1kRdA/viewform"
          >
            Inscreva-se
          </a>
        </PrimaryBtn>
      </S.Card>
      <S.CardContainer showContent={showContent}>
        <S.CardContent>
          <S.CardTitle>
            <S.CardButton
              title={`Saiba mais sobre IV Encontro de Urologia 2023`}
              onClick={handleShowContent}
              aria-expanded={showContent}
              aria-controls={`event-content`}
            >
              Mais Informações Sobre o Evento
              <ChevronDown
                color="#1f3864"
                className={showContent ? "open" : "closed"}
              />
            </S.CardButton>
          </S.CardTitle>
          <S.CardInfo
            aria-labelledby={"IV Encontro de Urologia 2023"}
            aria-hidden={!showContent}
            id={"urologia2023"}
          >
            <S.CardInfoTextWrapper>
              <S.CardInfoText>
                O IV Encontro de Urologia é um simpósio dedicado à discussão de
                alguns dos temas mais pertinentes em Urologia na atualidade tais
                como Cancros da Próstata e Bexiga, Incontinência Urinária e
                Litíase Urinária.
              </S.CardInfoText>
              <S.InfoWrapper>
                <S.InfoBox>
                  <S.InfoTitle>Local e Data</S.InfoTitle>
                  <S.Info>
                    <span style={{ fontWeight: 600 }}>Casino Park hotel</span>{" "}
                    <span style={{ color: "#ffd966" }}>|</span> Funchal
                    (Madeira)
                  </S.Info>
                  <S.Info>27 DE OUTUBRO DE 2023</S.Info>
                  <S.Info>A partir das 14h.</S.Info>
                </S.InfoBox>
                <S.InfoLinks>
                  <S.InfoTitle>Links e Downloads</S.InfoTitle>
                  <PrimaryBtn
                    bgColor={"#1f3864"}
                    onClick={() => handleDownload(urologiaDoc)}
                    title="Download IV Encontro de Urologia 2023.pdf"
                  >
                    Programa
                  </PrimaryBtn>
                  <PrimaryBtn bgColor={"#ffd966"} txtColor={"#1f3864"}>
                    <a
                      title="Ir para formulário de inscrição IV Encontro de Urologia 2023"
                      style={{ all: "unset", fontWeight: 600 }}
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfhwBqQoQGi-h1ONRIxnoT77JY6mtNj3J_sKodb_C0bB1kRdA/viewform"
                    >
                      Inscreva-se
                    </a>
                  </PrimaryBtn>
                </S.InfoLinks>
                <div>
                  <img src={sesaramLogo} alt="logo da empresa Sesaram" />{" "}
                </div>
              </S.InfoWrapper>
            </S.CardInfoTextWrapper>
          </S.CardInfo>
        </S.CardContent>
      </S.CardContainer>
    </S.Container>
  );
};

export default EventCard;
