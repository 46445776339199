import styled from "styled-components";

export const Container = styled.div`
  padding-top: 5rem;
  padding-bottom: 2rem;
  background: ${(props) => props.theme.brand.gradient};
`;

export const HeroContainer = styled.div`
  max-width: 74rem;
  margin: 0 auto;
  padding-inline: 2rem;
  /*  display: grid;
  gap: 0.5rem;

  @media (min-width: 55em) {
    grid-template-columns: 1fr 1fr;
  } */
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSize[32]};
  font-weight: ${(props) => props.theme.fontWeight[700]};
  color: ${(props) => props.theme.brand.green};

  @media (min-width: 55em) {
    font-size: ${(props) => props.theme.fontSize[48]};
  }
`;

export const Subtitle = styled.div`
  font-size: ${(props) => props.theme.fontSize[20]};
  line-height: 130%;

  @media (min-width: 55em) {
    font-size: ${(props) => props.theme.fontSize[24]};
    line-height: 160%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  @media (min-width: 30em) {
    gap: 2rem;
    justify-content: flex-start;
  }
`;

const ButtonBase = styled.a`
  all: unset;
  padding-block: 1rem;
  padding-inline: 1rem;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  @media (min-width: 55em) {
    padding-inline: 2rem;
  }
`;

export const WebButton = styled(ButtonBase)`
  background: ${(props) => props.theme.base.title};
  color: ${(props) => props.theme.base.background};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;

export const AppButton = styled(ButtonBase)`
  border: 1px solid ${(props) => props.theme.base.title};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;

      background: rgba(8, 112, 120, 0.1);
    }
  }
`;

export const ImageContainer = styled.div``;

export const ImageHero = styled.img``;

export const CardList = styled.div`
  padding-top: 2rem;
  max-width: 74rem;
  margin: 0 auto;
  padding-inline: 2rem;
  display: grid;
  gap: 2rem;

  @media (min-width: 55em) and (max-width: 75em) {
    grid-template-columns: 1fr 1fr;
    gap: 4rem 1rem;
  }

  @media (min-width: 75em) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const CardTitle = styled.div`
  font-weight: ${(props) => props.theme.fontWeight[700]};
`;

export const CardBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 1rem;
`;

export const CardText = styled.div`
  padding-left: 2rem;
  border-left: 2px solid ${(props) => props.theme.brand.green};
  min-height: 4.5rem;
`;
