import { useState } from "react";
/* components */
import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { Footer } from "../../components/Footer";
/* styles */
import * as S from "./styles";
import EventCard from "./components/EventCard";

export const Events = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <main>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <S.Container>
        <S.EventsContainer>
          <S.Title>Eventos</S.Title>
          <S.EventCardContainer>
            <EventCard />
          </S.EventCardContainer>
        </S.EventsContainer>
      </S.Container>
      <Footer />
    </main>
  );
};
