import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";
import { NavLink } from "react-router-dom";

export const Container = styled.header`
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.base.background};

  z-index: 1001;
`;

export const HeaderContainer = styled.div`
  max-width: 74rem;
  margin: 0 auto;
  padding-block: ${(props) => (props.navPadding ? "0.75rem" : "2rem")};
  border-bottom: ${(props) =>
    props.navPadding ? `1px solid ${props.theme.base.hover}` : "none"};
  padding-inline: 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  transition: all 0.2s ease-out;
`;

export const LogoContainer = styled.div`
  min-width: 8rem;
`;

export const MobileMenu = styled.div`
  display: flex;

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }

  @media (min-width: 55em) {
    display: none;
  }
`;

export const NavbarContainer = styled.div`
  display: none;
  gap: 2rem;

  @media (min-width: 55em) {
    display: flex;
  }
`;

export const NavbarItem = styled(LinkScroll)`
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-size: ${(props) => props.theme.fontSize[18]};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;

export const NavbarLinks = styled(NavLink)`
  all: unset;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-size: ${(props) => props.theme.fontSize[18]};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;

export const TermsButton = styled.div`
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-size: ${(props) => props.theme.fontSize[18]};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;

export const SignUpButton = styled.a`
  all: unset;
  padding-block: 0.5rem;
  padding-inline: 2rem;
  background: transparent;
  border: 1px solid ${(props) => props.theme.base.title};
  border-radius: 4px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      background: rgba(8, 112, 120, 0.1);
    }
  }

  display: none;

  @media (min-width: 55em) {
    display: flex;
  }
`;
