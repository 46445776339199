import styled from "styled-components";

export const Container = styled.div`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.brand.text};
`;

export const Card = styled.section`
  display: grid;

  padding: 1rem;
  gap: 1rem;

  @media (min-width: 55em) {
    display: flex;
    align-items: center;
  }
`;

export const EventTitle = styled.h1`
  font-size: ${(props) => props.theme.fontSize[24]};
  color: #1f3864;
  flex: 1;
`;

export const CardContainer = styled.div`
  overflow: hidden;
  border-radius: 8px;
  text-align: left;
  transition: all 300ms ease-in-out;
  opacity: 0.9;

  &:hover {
    transition: all 300ms ease-in-out;
    background: ${({ showContent }) =>
      !showContent ? "rgba(255, 217, 102, 0.1)" : ""};
    opacity: 1;
  }
`;

export const CardContent = styled.div`
  padding: 1rem;
`;

export const CardTitle = styled.h2`
  color: #1f3864;
  font-size: ${(props) => props.theme.fontSize[16]};
  font-weight: ${(props) => props.theme.fontWeight[700]};
`;

export const CardButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .closed {
    transition: transform 500ms ease-in-out;
  }

  .open {
    transition: transform 500ms ease-in-out;
    transform: rotate(180deg);
  }
`;

export const CardInfo = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows 500ms ease-in-out;

  &[aria-hidden="true"] {
    grid-template-rows: 0fr;
  }
`;

export const CardInfoTextWrapper = styled.div`
  overflow: hidden;
`;

export const CardInfoText = styled.p`
  padding-top: 0.5rem;
`;

export const InfoWrapper = styled.div`
  padding-top: 2rem;
  display: grid;
  gap: 1rem;

  @media (min-width: 55em) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const InfoTitle = styled.h3`
  position: relative;
  font-size: ${(props) => props.theme.fontSize[20]};
  color: #1f3864;
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
`;

export const InfoLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;

  @media (min-width: 55em) {
    align-items: center;
    border-right: 1px solid #ffd966;
    border-left: 1px solid #ffd966;
  }
`;

export const Info = styled.p`
  color: #1f3864;
  font-size: ${(props) => props.theme.fontSize[18]};
`;

/* DELETE */

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-block: 2rem;
`;

export const HeaderText = styled.div`
  font-size: ${(props) => props.theme.fontSize[18]};
  font-weight: ${(props) => props.theme.fontWeight[700]};
  color: ${(props) => props.theme.brand.text};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: justify;

  padding-bottom: 2.2rem;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSize[32]};
  font-weight: ${(props) => props.theme.fontWeight[700]};
  color: ${(props) => props.theme.brand.text};
`;

export const Subtitle = styled.div`
  font-size: ${(props) => props.theme.fontSize[18]};
`;

export const TermosText = styled.div`
  font-size: ${(props) => props.theme.fontSize[14]};
`;
