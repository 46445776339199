import React, { useEffect, useState } from "react";
import { LogoColor } from "../LogoColor";
import * as S from "./styles";
import { useTheme } from "styled-components";
import { NavLink } from "react-router-dom";
import { List } from "phosphor-react";
import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

export const Header = ({ toggle }) => {
  const theme = useTheme();
  const location = useLocation();
  const [navPadding, setNavpadding] = useState(false);
  const path = location.pathname;

  const linkCommonConfig = {
    offset: -62, //header height
    exact: "true",
    smooth: "true",
    duration: 500,
  };

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 1) {
        setNavpadding(true);
      } else {
        setNavpadding(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (section) => {
    setTimeout(() => {
      const element = document.getElementById(section);
      if (element) {
        const scrollTo = element.offsetTop - 62;
        scroll.scrollTo(scrollTo, {
          duration: 500,
          smooth: true,
        });
      }
    }, 200);
  };

  return (
    <S.Container>
      <S.HeaderContainer navPadding={navPadding}>
        <S.LogoContainer>
          <LogoColor />
        </S.LogoContainer>
        <S.MobileMenu onClick={() => toggle()}>
          <List size={32} weight="fill" color={theme.base.title} />
        </S.MobileMenu>
        <S.NavbarContainer>
          {path && path === "/" ? (
            <>
              <S.NavbarItem to={"home"} {...linkCommonConfig}>
                Home
              </S.NavbarItem>
              <S.NavbarItem to="sobre" {...linkCommonConfig}>
                Sobre
              </S.NavbarItem>
              <S.NavbarItem to="contactos" {...linkCommonConfig}>
                Contactos
              </S.NavbarItem>
            </>
          ) : (
            <>
              <S.NavbarLinks to="/">Home</S.NavbarLinks>
              <S.NavbarLinks to="/" onClick={() => scrollToSection("sobre")}>
                Sobre
              </S.NavbarLinks>
              <S.NavbarLinks
                to="/"
                onClick={() => scrollToSection("contactos")}
              >
                Contactos
              </S.NavbarLinks>
            </>
          )}
          <NavLink style={{ all: "unset" }} to={"/eventos"}>
            <S.TermsButton>Eventos</S.TermsButton>
          </NavLink>
          <NavLink style={{ all: "unset" }} to={"/termos"}>
            <S.TermsButton>Termos</S.TermsButton>
          </NavLink>
        </S.NavbarContainer>
        <S.SignUpButton
          href="https://create-booking-dashboard.azurewebsites.net/app/login"
          target="_blank"
          title="Login Create Booking"
        >
          Login
        </S.SignUpButton>
      </S.HeaderContainer>
    </S.Container>
  );
};
