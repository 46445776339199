import styled from "styled-components";
import { NavLink } from "react-router-dom";

const PrimaryBtn = styled.button`
  all: unset;
  padding-block: 1rem;
  padding-inline: 1rem;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  text-align: center;

  @media (min-width: 40em) {
    width: max-content;
    min-width: 138px;
  }

  background: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.brand.green};
  color: ${({ theme, txtColor }) =>
    txtColor ? txtColor : theme.base.background};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;

export default PrimaryBtn;
