import { Routes, Route } from "react-router-dom";
import { DefaultLayout } from "../layouts/DefaultLayout";

import { Home } from "../pages/Home";
import { Termos } from "../pages/Termos";
import { Events } from "../pages/Events";

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/termos" element={<Termos />} />
        <Route path="/eventos" element={<Events />} />
      </Route>
    </Routes>
  );
}
