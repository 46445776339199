/* components */
import { NavLink } from "react-router-dom";
import { ArrowUUpLeft } from "phosphor-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
/* styles */
import * as S from "./styles";
import "react-tabs/style/react-tabs.css";

export const Termos = () => {
  function getCurrentURL() {
    return window.location.href;
  }
  const url = getCurrentURL().replace("termos", "");

  return (
    <S.Container>
      <S.Content>
        <S.Header>
          <NavLink
            to={"/"}
            style={{ all: "unset", cursor: "pointer" }}
            title={"Voltar para Home"}
          >
            <ArrowUUpLeft size={32} weight="fill" />
          </NavLink>
          <S.HeaderText>Voltar</S.HeaderText>
        </S.Header>
        <Tabs>
          <TabList>
            <Tab>Termos e Condições</Tab>
            <Tab>Política de Privacidade</Tab>
          </TabList>
          <TabPanel>
            <S.TextContainer>
              <S.Title>Termos e Condições </S.Title>
              <S.TermosText>
                Bem-vindo ao Create Booking! Estes Termos e Condições ("Termos")
                regem seu acesso e uso do site Create Booking localizado em{" "}
                <span>{url}</span> ("Site"). Ao acessar ou usar o Site, você
                concorda em ficar vinculado a estes Termos. Se você não concorda
                com estes Termos, não deve usar o Site.
              </S.TermosText>
              <S.Subtitle>1. Uso do Site</S.Subtitle>
              <S.TermosText>
                O Create Booking fornece uma plataforma para os usuários
                agendarem e gerenciarem compromissos com várias empresas
                ("Prestadores de Serviços") listados no Site. Você concorda em
                usar o Site apenas para os fins a que se destina e de acordo com
                estes Termos.
              </S.TermosText>
              <S.Subtitle>2. Contas de usuário</S.Subtitle>
              <S.TermosText>
                Para usar certos recursos do Site, você pode precisar se
                registrar e criar uma conta de usuário. Você concorda em
                fornecer informações precisas e completas ao criar sua conta e
                em manter suas credenciais de login seguras. Você é responsável
                por quaisquer atividades que ocorram em sua conta.
              </S.TermosText>
              <S.Subtitle>3. Reservas e Pagamentos</S.Subtitle>
              <S.TermosText>
                Ao fazer uma reserva através do Site, você concorda em pagar ao
                Provedor de Serviços o valor especificado no momento da reserva.
                Os pagamentos são processados por provedores de pagamento
                terceirizados, e o Create Booking não é responsável por
                quaisquer disputas ou erros de pagamento.
              </S.TermosText>
              <S.Subtitle>4. Cancelamentos e Reembolsos</S.Subtitle>
              <S.TermosText>
                Caso necessite de cancelar uma reserva, deverá fazê-lo o mais
                rapidamente possível e de acordo com a política de cancelamento
                definida pelo Prestador de Serviços. A Create Booking não é
                responsável por quaisquer reembolsos ou cancelamentos, e você
                deve encaminhar quaisquer solicitações ou disputas ao Provedor
                de Serviços.
              </S.TermosText>
              <S.Subtitle>5. Conteúdo do usuário</S.Subtitle>
              <S.TermosText>
                Você pode enviar conteúdo, como críticas ou classificações, para
                o Site. Ao enviar conteúdo, você concede à Create Booking uma
                licença não exclusiva, mundial, isenta de royalties, perpétua e
                irrevogável para usar, copiar, distribuir e exibir tal conteúdo.
              </S.TermosText>
              <S.Subtitle>6. Propriedade intelectual</S.Subtitle>
              <S.TermosText>
                O Site e todo o seu conteúdo, incluindo, entre outros, texto,
                gráficos, logotipos, imagens e software, são propriedade da
                Create Booking ou de seus licenciadores e são protegidos por
                direitos autorais, marcas registradas e outras leis de
                propriedade intelectual.
              </S.TermosText>
              <S.Subtitle>7. Isenção de Garantias</S.Subtitle>
              <S.TermosText>
                O Site é fornecido "como está" e "conforme disponível", sem
                garantias de qualquer tipo, expressas ou implícitas, incluindo,
                entre outras, garantias de comercialização, adequação a uma
                finalidade específica ou não violação.
              </S.TermosText>
              <S.Subtitle>8. Limitação de responsabilidade</S.Subtitle>
              <S.TermosText>
                A Create Booking não é responsável por quaisquer danos,
                incluindo, entre outros, danos diretos, indiretos, incidentais,
                consequentes ou punitivos, decorrentes ou relacionados ao uso do
                Site ou qualquer conteúdo ou serviços disponíveis através do
                Site.
              </S.TermosText>
              <S.Subtitle>9. Indenização</S.Subtitle>
              <S.TermosText>
                Você concorda em indenizar e isentar a Create Booking e suas
                afiliadas, executivos, diretores, funcionários e agentes de
                quaisquer reivindicações, perdas, danos, responsabilidades e
                despesas, incluindo honorários advocatícios razoáveis,
                decorrentes ou relacionados ao seu uso do Site ou sua violação
                destes Termos.
              </S.TermosText>
              <S.Subtitle>10. Lei Aplicável</S.Subtitle>
              <S.TermosText>
                Estes Termos e seu uso do Site são regidos e interpretados de
                acordo com as leis do estado da Califórnia, sem dar efeito a
                qualquer escolha ou disposição de conflito de leis.
              </S.TermosText>
              <S.Subtitle>11. Resolução de Disputas</S.Subtitle>
              <S.TermosText>
                Qualquer disputa decorrente ou relacionada a estes Termos ou ao
                seu uso do Site será resolvida por meio de arbitragem
                obrigatória, administrada pela Associação Americana de
                Arbitragem de acordo com suas Regras de Arbitragem Comercial.
              </S.TermosText>
              <S.Subtitle>12. Alterações a estes Termos</S.Subtitle>
              <S.TermosText>
                A Create Booking pode atualizar estes Termos de tempos em
                tempos, publicando uma versão revisada no Site. Seu uso
                continuado do Site após tais alterações constituirá sua
                aceitação dos Termos revisados.
              </S.TermosText>
            </S.TextContainer>
          </TabPanel>
          <TabPanel>
            <S.TextContainer>
              <S.Title>Política de Privacidade</S.Title>
              <S.Subtitle>1. Legalidade, equidade e transparência</S.Subtitle>
              <S.TermosText>
                A informação processada pela Create Booking tem como único
                propósito o controlo, organização e gestão de serviços e
                despesas dentro dos parâmetros definidos pelo cliente.
              </S.TermosText>

              <S.Subtitle>2. Limitação da finalidade</S.Subtitle>
              <S.TermosText>
                Apenas os dados necessários à execução das tarefas, da
                informação a prestar ao cliente e ao cumprimento de obrigações
                legais (report ao Infarmed) são recolhidos, armazenados e
                processados. As métricas apresentadas pela plataforma têm como
                base a atividade comercial da Create Booking, nomeadamente o
                controlo e acompanhamento das faturas emitidas pelos
                fornecedores e pagas pela CreateBooking. Toda a metodologia é
                aplicada segundo os parâmetros definidos pelo mesmo por forma a
                conseguir assegurar a verificação dos serviços prestados.
              </S.TermosText>

              <S.Subtitle>3. Minimização de dados</S.Subtitle>
              <S.TermosText>
                Apenas a informação essencial à prestação do serviço é
                utilizada, processada e armazenada. Casos específicos, como a
                reserva de um voo ou uma estadia num hotel, podem exigir a
                recolha e o tratamento de informações adicionais, mas apenas
                porque sem elas não seria possível prestar o serviço. Na maioria
                dos casos, apenas são utilizados o nome e o número da ordem do
                médico e do local de trabalho (informação que é pública e está
                disponível no site da Ordem dos Médicos).
              </S.TermosText>
              <S.Subtitle>4. Exatidão</S.Subtitle>
              <S.TermosText>
                Todas as informações gerenciadas são fornecidas pelo cliente e
                cabe ao cliente garantir a precisão das mesmas. Qualquer
                alteração é aplicável imediatamente mediante solicitação.
              </S.TermosText>

              <S.Subtitle>5. Limitação de armazenamento</S.Subtitle>
              <S.TermosText>
                A informação é armazenada pelo período legal exigido, para o
                cumprimento das obrigações legais, fiscais e também pelas
                obrigações comerciais e validação da veracidade da mesma.
              </S.TermosText>
              <S.Subtitle>6. Integridade e Confidencialidade</S.Subtitle>
              <S.TermosText>
                Toda a informação encontra-se armazenada numa base de dados
                encriptada acessível a partir de um conjunto de endereço
                internet (IP addresses) pré-definidos (Whitelist), exigindo a
                utilização de credenciais “Username” + “Password” específicas de
                cada utilizador e um conjunto de ações possíveis de executar
                sobre a BD, sendo o nível de acesso à informação definido por um
                gestor/supervisor da plataforma definido pela empresa. As
                credenciais (username + password) que permitem acesso à
                plataforma de software são geradas automaticamente pela
                plataforma estando limitado em termos de funcionalidades e
                acesso à informação pelo perfil associado ao utilizador.
              </S.TermosText>
              <S.TermosText>
                Em caso de necessidade existe a possibilidade de dissociar o
                serviço (código) da informação (base de dados) permitindo a
                possibilidade de todos os dados do cliente geridos pela
                plataforma, quer estejam armazenados num servidor escolhido pelo
                cliente (cloud ou on premise). Independentemente do local de
                armazenamento, o acesso à informação terá de ser feito a partir
                da plataforma de software.
              </S.TermosText>
              <S.Subtitle>7. Prestação de contas</S.Subtitle>
              <S.TermosText>
                O serviço prestado exige a necessidade de gerar relatórios e
                exportar blocos de informação, o acesso a estas de
                funcionalidade é garantido ou negado por defeito, e as
                permissões/restrições à informação definida pelo cliente. A
                plataforma garante a documentação do acesso à informação (Logs)
                nos seus vários estágios.
              </S.TermosText>
            </S.TextContainer>
          </TabPanel>
        </Tabs>
      </S.Content>
    </S.Container>
  );
};
