import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";
import { NavLink } from "react-router-dom";

export const Container = styled.header`
  background: ${(props) => props.theme.brand.green};
`;

export const FooterContainer = styled.div`
  max-width: 74rem;
  margin: 0 auto;
  padding: 4rem 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;

  transition: all 0.2s ease-out;

  @media (min-width: 55em) {
    flex-direction: row;
    gap: 0;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const NavbarItem = styled(LinkScroll)`
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-size: ${(props) => props.theme.fontSize[18]};
  color: ${(props) => props.theme.base.white};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;

export const NavbarLinks = styled(NavLink)`
  all: unset;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-size: ${(props) => props.theme.fontSize[18]};
  color: ${(props) => props.theme.base.white};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;

export const TermsButton = styled.div`
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-size: ${(props) => props.theme.fontSize[18]};
  color: ${(props) => props.theme.base.white};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;

export const FooterText = styled.p`
  flex: 1;
  text-align: center;
  color: ${(props) => props.theme.base.white};
  font-size: ${(props) => props.theme.fontSize[14]};
`;

export const FooterCopyRightsLink = styled.a`
  text-decoration: none;
  text-align: center;
  color: ${(props) => props.theme.base.white};
  font-size: ${(props) => props.theme.fontSize[14]};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      text-decoration: underline;
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;
