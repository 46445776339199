import React from "react";
import { useTheme } from "styled-components";

export const Logo = ({ color }) => {
  const theme = useTheme();
  return (
    <svg fill="none" viewBox="0 0 302 103">
      <g fill={color ?? theme.base.text} clipPath="url(#clip0_101_26)">
        <path d="M193.83 33.04c0 3.65-3 6.61-6.7 6.61s-6.7-2.96-6.7-6.61c0-3.65 3-6.61 6.7-6.61s6.7 2.96 6.7 6.61zM51.11 6.47c0 3.65-3 6.61-6.7 6.61s-6.7-2.96-6.7-6.61c0-3.65 3-6.61 6.7-6.61 3.7-.01 6.7 2.95 6.7 6.61zM108.76-.15l-20.15 39.8h40.3L108.76-.15zm2.63 32.38c-5.62 2.18-10.9-3.03-8.69-8.59a6.052 6.052 0 013.43-3.39c5.62-2.18 10.9 3.04 8.69 8.59a6.052 6.052 0 01-3.43 3.39zM126.17-.15v9.28h8.15v30.52h8.9V9.13h8.16V-.15h-25.21zM36.048 39.656v-39.8h-8.9v39.8h8.9zM72.93 30.94c-5.13 0-9.48-3.23-11.15-7.74h11.15v-.03h19.83c.2-1.11.32-2.25.32-3.42 0-10.99-9.02-19.9-20.15-19.9-11.13 0-20.15 8.91-20.15 19.9 0 10.99 9.02 19.9 20.15 19.9v-8.71zm0-22.96c5.13 0 9.48 3.23 11.15 7.74h-22.3c1.66-4.51 6.02-7.74 11.15-7.74z"></path>
        <path d="M173.68 30.94c-5.13 0-9.48-3.23-11.15-7.74h11.15v-.03h19.83c.2-1.11.32-2.25.32-3.42 0-10.99-9.02-19.9-20.15-19.9-11.13 0-20.15 8.91-20.15 19.9 0 10.99 9.02 19.9 20.15 19.9v-8.71zm0-22.96c5.13 0 9.48 3.23 11.15 7.74h-22.29c1.66-4.51 6.01-7.74 11.14-7.74zM21.24 31.39c-6.26-.7-11.11-5.64-11.11-11.63 0-5.99 4.85-10.94 11.11-11.64V-.15c-11.13 0-20.15 8.91-20.15 19.9 0 10.99 9.02 19.9 20.15 19.9v-8.26zM.4 100.98V47.67H23c6.26 0 10.87 1.27 13.84 3.8 2.97 2.53 4.46 6.11 4.46 10.74 0 2.85-.83 5.39-2.5 7.59-1.67 2.21-4.12 3.61-7.35 4.2v.08c3.6.54 6.36 1.94 8.29 4.2 1.93 2.26 2.89 4.96 2.89 8.08 0 4.58-1.6 8.16-4.81 10.74-3.2 2.58-7.88 3.88-14.03 3.88H.4zm10.48-30.94h10.71c5.68 0 8.52-2.29 8.52-6.87s-2.84-6.87-8.52-6.87H10.88v13.74zm0 22.54h11.96c2.92 0 5.08-.62 6.49-1.86 1.41-1.24 2.11-2.96 2.11-5.17 0-2.26-.7-3.98-2.11-5.17-1.41-1.18-3.57-1.78-6.49-1.78H10.88v13.98zM175.17 93.2l-16.48-20.75 15.35-16.46.13-7.84h-7.34l-14.16 16.72-7.03-8.86v-7.86h-10.79V101h10.79V73.18L167.61 101h7.6l-.04-7.8zM192.7 48.15V101h-10.79V48.15h10.79zM245.07 48.15V101h-12.52l-18.3-31.07-4.52-8.65h-.08l.32 9.13V101h-9.82V48.15h12.44l18.3 30.99 4.52 8.73h.16l-.32-9.13V48.15h9.82zM101.91 46.86c5.37 0 10 1.1 13.89 3.31 3.89 2.21 6.89 5.36 9 9.45 2.11 4.09 3.16 8.99 3.16 14.7 0 5.71-1.06 10.61-3.16 14.7-2.11 4.09-5.11 7.24-9 9.45-3.89 2.21-8.52 3.31-13.89 3.31-5.37 0-10.01-1.1-13.93-3.31s-6.93-5.36-9.04-9.45c-2.11-4.09-3.17-8.99-3.17-14.7 0-5.71 1.05-10.61 3.17-14.7 2.11-4.09 5.12-7.24 9.04-9.45 3.92-2.21 8.56-3.31 13.93-3.31zm0 9.13c-3.07 0-5.67.7-7.81 2.1-2.14 1.4-3.77 3.46-4.89 6.18-1.12 2.72-1.69 6.07-1.69 10.06 0 3.93.56 7.27 1.69 10.02 1.12 2.75 2.75 4.82 4.89 6.22 2.14 1.4 4.74 2.1 7.81 2.1 3.01 0 5.59-.7 7.73-2.1 2.14-1.4 3.77-3.47 4.89-6.22 1.12-2.75 1.68-6.08 1.68-10.02 0-3.98-.56-7.34-1.68-10.06-1.12-2.72-2.75-4.78-4.89-6.18-2.14-1.4-4.71-2.1-7.73-2.1z"></path>
        <path d="M76.49 46.86c5.37 0 10 1.1 13.89 3.31 3.89 2.21 6.89 5.36 9 9.45 2.11 4.09 3.16 8.99 3.16 14.7 0 5.71-1.06 10.61-3.16 14.7-2.11 4.09-5.11 7.24-9 9.45-3.89 2.21-8.52 3.31-13.89 3.31-5.37 0-10.01-1.1-13.93-3.31s-6.93-5.36-9.04-9.45c-2.11-4.09-3.17-8.99-3.17-14.7 0-5.71 1.05-10.61 3.17-14.7 2.11-4.09 5.12-7.24 9.04-9.45 3.92-2.21 8.56-3.31 13.93-3.31zm0 9.13c-3.07 0-5.67.7-7.81 2.1-2.14 1.4-3.77 3.46-4.89 6.18-1.12 2.72-1.69 6.07-1.69 10.06 0 3.93.56 7.27 1.69 10.02 1.12 2.75 2.75 4.82 4.89 6.22 2.14 1.4 4.74 2.1 7.81 2.1 3.01 0 5.59-.7 7.73-2.1 2.14-1.4 3.77-3.47 4.89-6.22 1.12-2.75 1.68-6.08 1.68-10.02 0-3.98-.56-7.34-1.68-10.06-1.12-2.72-2.75-4.78-4.89-6.18-2.14-1.4-4.71-2.1-7.73-2.1zM290.12 75.17v9.45l-8.7-9.84h-6.25v8.96l7.79 8.71c-1.41.35-2.92.54-4.53.54-3.28 0-6.03-.68-8.25-2.05-2.23-1.36-3.91-3.42-5.03-6.18-1.13-2.76-1.69-6.14-1.69-10.15 0-3.91.59-7.21 1.77-9.91 1.18-2.7 2.87-4.76 5.07-6.18s4.8-2.13 7.81-2.13c.53 0 1.02.03 1.51.07 2.36.19 4.39.85 6.11 1.98 1.53 1.01 3 2.01 4.01 3.61.02.04.05.08.07.11.13.22.25.45.36.69.05.1.08.18.12.27.12.27.23.56.32.86h9.97l-.16-3.65c-5.93-14.65-22.39-13-22.39-13-5.21 0-9.78 1.1-13.73 3.29-3.95 2.19-6.99 5.34-9.14 9.43-2.15 4.09-3.22 8.98-3.22 14.65 0 5.73 1.02 10.62 3.06 14.69s4.9 7.17 8.58 9.31c3.68 2.14 7.96 3.21 12.85 3.21 1.24 0 2.41-.08 3.53-.22 6.5-.41 10.47-2.46 10.47-2.46v1.27h10.46V75.17h-10.77z"></path>
      </g>
      <defs>
        <clipPath id="clip0_101_26">
          <path fill="#fff" d="M0 0H301.47V102.05H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
