import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
/* styles */
import * as S from "./styles";

const validationSchema = Yup.object().shape({
  nome: Yup.string().required("Campo obrigatório"),
  apelido: Yup.string().required("Campo obrigatório"),
  email: Yup.string().email("Email inválido").required("Campo obrigatório"),
  telefone: Yup.string().required("Campo obrigatório"),
  empresa: Yup.string().required("Campo obrigatório"),
});

const initialValues = {
  nome: "",
  apelido: "",
  email: "",
  telefone: "",
  empresa: "",
};

export const Contactos = () => {
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    fetch("/send-email.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        setSubmitting(false);
        resetForm();
        alert("Email foi enviado com sucesso!");
      })
      .catch((error) => {
        alert("Ocorreu um erro ao tentar enviar o email.");
      });
  };

  return (
    <S.Container id="contactos">
      <S.InfoContainer>
        <S.TextContainer>
          <S.Title>Entre em contacto com a Create</S.Title>
          <S.Subtitle>
            Prencha o formulário, envie e receba no seu email um acesso à
            plataforma de demonstração, disponivel com todas as funcionalidades,
            onde poderá testar ao vivo e perceber como se pode adaptar às suas
            necessidades.
          </S.Subtitle>
        </S.TextContainer>
      </S.InfoContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isValid, isSubmitting, dirty }) => (
          <Form id="contactos">
            <S.FormContainer>
              <S.InputContainer>
                <label htmlFor={"nome"}>Nome</label>
                <S.Input
                  type="text"
                  id="nome"
                  name="nome"
                  placeholder={touched.nome && errors.nome ? errors.nome : ""}
                  required
                  className={touched.nome && errors.nome ? "has-error" : ""}
                />
              </S.InputContainer>

              <S.InputContainer>
                <label htmlFor={"apelido"}>Apelido</label>
                <S.Input
                  type="text"
                  id="apelido"
                  name="apelido"
                  placeholder={
                    touched.apelido && errors.apelido ? errors.apelido : ""
                  }
                  className={
                    touched.apelido && errors.apelido ? "has-error" : ""
                  }
                  required
                />
              </S.InputContainer>

              <S.InputContainer>
                <label htmlFor={"email"}>Email</label>
                <S.Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={
                    touched.email && errors.email ? errors.email : ""
                  }
                  className={touched.email && errors.email ? "has-error" : ""}
                  required
                />
              </S.InputContainer>

              <S.InputContainer>
                <label htmlFor={"telefone"}>Telefone</label>
                <S.Input
                  type="tel"
                  id="telefone"
                  name="telefone"
                  placeholder={
                    touched.telefone && errors.telefone ? errors.telefone : ""
                  }
                  className={
                    touched.telefone && errors.telefone ? "has-error" : ""
                  }
                  required
                />
              </S.InputContainer>

              <S.CompanyInput>
                <label htmlFor={"empresa"}>Empresa</label>
                <S.Input
                  type="text"
                  id="empresa"
                  name="empresa"
                  placeholder={
                    touched.empresa && errors.empresa ? errors.empresa : ""
                  }
                  className={
                    touched.empresa && errors.empresa ? "has-error" : ""
                  }
                  required
                />
              </S.CompanyInput>
            </S.FormContainer>
            <S.Button type="submit">Enviar</S.Button>
          </Form>
        )}
      </Formik>
    </S.Container>
  );
};
