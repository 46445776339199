import styled from "styled-components";

const SecondaryBtn = styled.button`
  all: unset;
  padding-block: 1rem;
  padding-inline: 1rem;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  text-align: center;

  @media (min-width: 40em) {
    width: max-content;
    min-width: 138px;
  }

  background: ${(props) => props.theme.base.title};
  color: ${(props) => props.theme.base.background};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;

export default SecondaryBtn;
