import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 2rem;
`;

export const AboutContainer = styled.div`
  max-width: 74rem;
  margin: 0 auto;
  padding-inline: 2rem;
  display: grid;
  gap: 2rem;

  @media (min-width: 55em) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: flex-end;
`;

export const LogoContainer = styled.div``;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding-bottom: 2.2rem;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSize[32]};
  font-weight: ${(props) => props.theme.fontWeight[700]};
  color: ${(props) => props.theme.brand.text};
`;

export const Subtitle = styled.div`
  font-size: ${(props) => props.theme.fontSize[20]};
  line-height: 130%;
`;

export const ImageContainer = styled.div``;

export const ImageDisplay = styled.img`
  mix-blend-mode: multiply;
`;
