import styled from "styled-components";
import { Formik, Form, Field } from "formik";

/* export const BackgroundContainer = styled.div`
  background: ${(props) => props.theme.brand.gradient};
`;
 */
export const Container = styled.div`
  padding-top: 4rem;
  padding-bottom: 16rem;
  display: flex;
  flex-direction: column;

  max-width: 74rem;
  margin: 0 auto;
  padding-inline: 2rem;
`;

export const InfoContainer = styled.div``;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding-bottom: 2rem;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSize[48]};
  font-weight: ${(props) => props.theme.fontWeight[700]};
  color: ${(props) => props.theme.brand.green};
`;

export const Subtitle = styled.div`
  font-size: ${(props) => props.theme.fontSize[20]};
  line-height: 130%;
`;

export const FormContainer = styled.div`
  display: grid;
  gap: 1rem;
  padding-bottom: 2rem;

  @media (min-width: 55em) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const InputContainer = styled.div`
  display: grid;
  gap: 0.4rem;

  .has-error {
    border: 1px solid #f00;
    ::placeholder {
      color: #f00;
    }
  }
`;

export const Input = styled(Field)`
  color: ${(props) => props.theme.base.text};
  font-size: ${(props) => props.theme.fontSize[14]};
  padding: 0.75rem;
  border: 1px solid ${(props) => props.theme.base.button};
  background: ${(props) => props.theme.base.input};
  border-radius: 4px;

  :focus {
    outline: 0;
    box-shadow: 0 0 0 0px;
  }

  ::placeholder {
    color: ${(props) => props.theme.base.label};
  }

  input:invalid::placeholder {
    color: #f00;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      border: 1px solid ${(props) => props.theme.brand.green};
    }
  }
`;

export const CompanyInput = styled(InputContainer)`
  grid-column: 1 / -1;
`;

export const Error = styled.div`
  color: red;
  margin-top: 0.5rem;
`;

export const Button = styled.button`
  all: unset;
  padding-block: 1rem;
  padding-inline: 2rem;
  background: ${(props) => props.theme.base.title};
  color: ${(props) => props.theme.base.background};
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;
