export const defaultTheme = {
  brand: {
    green: "#087078",
    gradient: `radial-gradient(
    circle,
    rgba(250, 250, 250, 1) 0%,
    rgba(8, 112, 120, 0.3) 100%
  )`,
  },
  base: {
    white: "#FFFFFF",
    background: "#FAFAFA",
    card: "#F3F2F2",
    input: "#EDEDED",
    button: "#E6E5E5",
    hover: "#D7D5D5",
    label: "#8D8686",
    text: "#574F4D",
    subtitle: "#403937",
    title: "#272221",
  },
  font: {
    main: `'Roboto', sans-serif`,
  },
  fontSize: {
    10: "0.625rem",
    12: "0.75rem",
    14: "0.875rem",
    16: "1rem",
    18: "1.125rem",
    20: "1.25rem",
    24: "1.5rem",
    32: "2rem",
    48: "3rem",
  },
  fontWeight: {
    400: 400,
    700: 700,
  },
  lineHeight: {
    130: "130%",
    160: "160%",
  },
};
