import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

export const Footer = ({ toggle }) => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const path = location.pathname;

  const linkCommonConfig = {
    offset: -62, //header height
    exact: "true",
    smooth: "true",
    duration: 500,
  };

  const scrollToSection = (section) => {
    setTimeout(() => {
      const element = document.getElementById(section);
      if (element) {
        const scrollTo = element.offsetTop - 62;
        scroll.scrollTo(scrollTo, {
          duration: 500,
          smooth: true,
        });
      }
    }, 200);
  };

  return (
    <S.Container>
      <S.FooterContainer>
        <S.NavbarContainer>
          {path && path === "/" ? (
            <>
              <S.NavbarItem to={"home"} {...linkCommonConfig}>
                Home
              </S.NavbarItem>
              <S.NavbarItem to="sobre" {...linkCommonConfig}>
                Sobre
              </S.NavbarItem>
              <S.NavbarItem to="contactos" {...linkCommonConfig}>
                Contactos
              </S.NavbarItem>
            </>
          ) : (
            <>
              <S.NavbarLinks to="/">Home</S.NavbarLinks>
              <S.NavbarLinks to="/" onClick={() => scrollToSection("sobre")}>
                Sobre
              </S.NavbarLinks>
              <S.NavbarLinks
                to="/"
                onClick={() => scrollToSection("contactos")}
              >
                Contactos
              </S.NavbarLinks>
            </>
          )}
          <NavLink style={{ all: "unset" }} to={"/eventos"}>
            <S.TermsButton>Eventos</S.TermsButton>
          </NavLink>
        </S.NavbarContainer>
        <S.FooterText>{`© ${currentYear} Create Booking`}</S.FooterText>
      </S.FooterContainer>
    </S.Container>
  );
};
