import { X } from "phosphor-react";
import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

import * as S from "./styles";

export const Sidebar = ({ toggle, isOpen }) => {
  const location = useLocation();
  const path = location.pathname;

  const scrollToSection = (section) => {
    toggle();
    setTimeout(() => {
      const element = document.getElementById(section);
      if (element) {
        const scrollTo = element.offsetTop - 62;
        scroll.scrollTo(scrollTo, {
          duration: 500,
          smooth: true,
        });
      }
    }, 200);
  };

  return (
    <S.SidebarContainer isOpen={isOpen} onClick={() => toggle()}>
      <S.Icon onClick={() => toggle()}>
        <X size={40} />
      </S.Icon>
      <S.SidebarWrapper>
        <S.SidebarMenu>
          {path && path === "/" ? (
            <>
              {" "}
              <S.SidebarLink to="home" offset={-62} onClick={() => toggle()}>
                Home
              </S.SidebarLink>
              <S.SidebarLink to="sobre" exact="true" onClick={() => toggle()}>
                Sobre
              </S.SidebarLink>
              <S.SidebarLink
                to="contactos"
                exact="true"
                onClick={() => toggle()}
              >
                Contactos
              </S.SidebarLink>
            </>
          ) : (
            <>
              <S.SidebarRouterLink to="/">Home</S.SidebarRouterLink>
              <S.SidebarRouterLink
                to="/"
                onClick={() => scrollToSection("sobre")}
              >
                Sobre
              </S.SidebarRouterLink>
              <S.SidebarRouterLink
                to="/"
                onClick={() => scrollToSection("contactos")}
              >
                Contactos
              </S.SidebarRouterLink>
            </>
          )}

          <S.TermsButton to="/eventos" onClick={() => toggle()}>
            Eventos
          </S.TermsButton>
          <S.TermsButton to="/termos" onClick={() => toggle()}>
            Termos
          </S.TermsButton>
          <S.SignUpButton
            href="https://create-booking-dashboard.azurewebsites.net/app/login"
            target="_blank"
            title="Login Create Booking"
          >
            Login
          </S.SignUpButton>
        </S.SidebarMenu>
      </S.SidebarWrapper>
    </S.SidebarContainer>
  );
};
