import { useState } from "react";
/* components */
import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { About } from "./components/About";
import { Contactos } from "./components/Contactos";
import { Hero } from "./components/Hero";
import { Footer } from "../../components/Footer";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <main>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <Hero />
      <About />
      <Contactos />
      <Footer />
    </main>
  );
};
