import React from "react";
import { useTheme } from "styled-components";

export const LogoColor = () => {
  const theme = useTheme();
  return (
    <svg fill="none" viewBox="0 0 193 41">
      <path
        fill={theme.base.title}
        d="M192.83 34.04c0 3.65-3 6.61-6.7 6.61s-6.7-2.96-6.7-6.61c0-3.65 3-6.61 6.7-6.61s6.7 2.96 6.7 6.61zM50.11 7.47c0 3.65-3 6.61-6.7 6.61s-6.7-2.96-6.7-6.61c0-3.65 3-6.61 6.7-6.61 3.7-.01 6.7 2.95 6.7 6.61z"
      ></path>
      <path
        fill="#66C5B8"
        d="M107.76.85l-20.15 39.8h40.3L107.76.85zm2.63 32.38c-5.62 2.18-10.9-3.03-8.69-8.59a6.052 6.052 0 013.43-3.39c5.62-2.18 10.9 3.04 8.69 8.59a6.052 6.052 0 01-3.43 3.39z"
      ></path>
      <path
        fill={theme.base.title}
        d="M125.17.85v9.28h8.15v30.52h8.9V10.13h8.16V.85h-25.21zM35.048 40.656V.856h-8.9v39.8h8.9zM71.93 31.94c-5.13 0-9.48-3.23-11.15-7.74h11.15v-.03h19.83c.2-1.11.32-2.25.32-3.42 0-10.99-9.02-19.9-20.15-19.9-11.13 0-20.15 8.91-20.15 19.9 0 10.99 9.02 19.9 20.15 19.9v-8.71zm0-22.96c5.13 0 9.48 3.23 11.15 7.74h-22.3c1.66-4.51 6.02-7.74 11.15-7.74z"
      ></path>
      <path
        fill="#FFCB4F"
        d="M172.68 31.94c-5.13 0-9.48-3.23-11.15-7.74h11.15v-.03h19.83c.2-1.11.32-2.25.32-3.42 0-10.99-9.02-19.9-20.15-19.9-11.13 0-20.15 8.91-20.15 19.9 0 10.99 9.02 19.9 20.15 19.9v-8.71zm0-22.96c5.13 0 9.48 3.23 11.15 7.74h-22.29c1.66-4.51 6.01-7.74 11.14-7.74z"
      ></path>
      <path
        fill="#3FB7E3"
        d="M20.24 32.39c-6.26-.7-11.11-5.64-11.11-11.63 0-5.99 4.85-10.94 11.11-11.64V.85C9.11.85.09 9.76.09 20.75c0 10.99 9.02 19.9 20.15 19.9v-8.26z"
      ></path>
    </svg>
  );
};
