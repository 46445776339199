import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../../components/Header";
import { ScrollToTop } from "../../components/ScrollToTop";
import { LayoutContainer } from "./styles";

export const DefaultLayout = () => {
  return (
    <LayoutContainer>
      <ScrollToTop />
      {/*  <Header /> */}
      <Outlet />
    </LayoutContainer>
  );
};
