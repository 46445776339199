import styled from "styled-components";

export const Container = styled.section`
  padding-bottom: 16rem;
`;

export const EventsContainer = styled.div`
  max-width: 74rem;
  margin: 0 auto;
  padding-inline: 2rem;

  display: grid;
  gap: 2rem;
`;

export const EventCardContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSize[48]};
  font-weight: ${(props) => props.theme.fontWeight[700]};
  color: ${(props) => props.theme.brand.green};
`;
