import displayImage from "../../../../assets/dispositivos.png";
import { Logo } from "../../../../components/Logo";
import { useTheme } from "styled-components";
/* styles */
import * as S from "./styles";

export const About = () => {
  const theme = useTheme();
  return (
    <S.Container id="sobre">
      <S.AboutContainer>
        <S.ImageContainer>
          <S.ImageDisplay src={displayImage} />
        </S.ImageContainer>
        <S.InfoContainer>
          <S.LogoContainer>
            <Logo color={theme.brand.green} />
          </S.LogoContainer>
          <S.TextContainer>
            <S.Title>Versão Desktop e App mobile</S.Title>
            <S.Subtitle>
              Acesso à Create Booking em qualquer lugar, aplicação especifica
              para dispositivos móveis, sincronização automática de dados,
              informação actualizada e sempre disponivel em qualquer
              dispositivo.
            </S.Subtitle>
          </S.TextContainer>
        </S.InfoContainer>
      </S.AboutContainer>
    </S.Container>
  );
};
