import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 74rem;
  margin: 0 auto;
  padding-inline: 2rem;
`;

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-block: 2rem;
`;

export const HeaderText = styled.div`
  font-size: ${(props) => props.theme.fontSize[18]};
  font-weight: ${(props) => props.theme.fontWeight[700]};
  color: ${(props) => props.theme.brand.text};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: justify;

  padding-bottom: 2.2rem;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSize[32]};
  font-weight: ${(props) => props.theme.fontWeight[700]};
  color: ${(props) => props.theme.brand.text};
`;

export const Subtitle = styled.div`
  font-size: ${(props) => props.theme.fontSize[18]};
`;

export const TermosText = styled.div`
  font-size: ${(props) => props.theme.fontSize[14]};
`;
