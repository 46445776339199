import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const CarouselContainer = styled.div`
  .glide__bullet {
    width: 20px;
    height: 20px;
  }
`;

export const ImageWrapper = styled.div``;

export const HeroContainer = styled.div`
  max-width: 74rem;
  margin: 0 auto;
  padding-inline: 2rem;
  display: grid;
  gap: 0.5rem;

  @media (min-width: 55em) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const HeroContainerEvent = styled.div`
  max-width: 74rem;
  margin: 0 auto;
  padding-inline: 2rem;
  display: grid;
  gap: 2rem;

  @media (min-width: 55em) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSize[32]};
  font-weight: ${(props) => props.theme.fontWeight[700]};
  color: ${(props) => props.theme.brand.green};

  @media (min-width: 55em) {
    font-size: ${(props) => props.theme.fontSize[48]};
  }
`;

export const Subtitle = styled.div`
  font-size: ${(props) => props.theme.fontSize[20]};
  line-height: 130%;

  @media (min-width: 55em) {
    font-size: ${(props) => props.theme.fontSize[24]};
    line-height: 160%;
  }
`;

export const SubtitleEvent = styled.div`
  font-size: ${(props) => props.theme.fontSize[18]};
  line-height: 130%;

  @media (min-width: 55em) {
    font-size: ${(props) => props.theme.fontSize[20]};
    line-height: 160%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const ButtonBase = styled.a`
  all: unset;
  width: 100%;
  padding-block: 1rem;
  padding-inline: 1rem;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  text-align: center;

  @media (min-width: 40em) {
    width: max-content;
    min-width: 138px;
  }
`;

export const WebButton = styled(ButtonBase)`
  background: ${(props) => props.theme.base.title};
  color: ${(props) => props.theme.base.background};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;

export const WebButtonEvent = styled(NavLink)`
  all: unset;
  width: 100%;
  padding-block: 1rem;
  padding-inline: 1rem;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  text-align: center;

  @media (min-width: 40em) {
    width: max-content;
    min-width: 138px;
  }

  background: ${(props) => props.theme.brand.green};
  color: ${(props) => props.theme.base.background};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;

export const AppButton = styled(ButtonBase)`
  border: 1px solid ${(props) => props.theme.base.title};

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus-within {
      transition: all 0.1s ease-in-out;

      background: rgba(8, 112, 120, 0.1);
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const ImageHero = styled.img``;

export const CopyRightsLink = styled.a`
  position: absolute;
  right: 0;
  text-decoration: none;
  text-align: center;
  color: ${(props) => props.theme.brand.green};
  font-size: ${(props) => props.theme.fontSize[12]};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      text-decoration: underline;
      transition: all 0.1s ease-in-out;
      opacity: 0.8;
    }
  }
`;
